<template>
  <div>
    <div class="screen_filter"></div>
    <!--상품 선택 팝업 시작-->
    <div class="popup popup_s_detail popup_s_detail_01" v-if="this.step==1">
      <button @click="closePopup">
        <img src="@/assets/images/common/icon_close_w.svg" alt="x버튼" />
      </button>
      <div class="inner">
        <!--제목 시작-->
        <div class="product_title">
          <h1>
            <p>상품 제안하기</p>
            <span class="gt">></span>
            <p>등록된 상품 선택 (01/03)</p>
          </h1>
        </div>
        <!--제목 끝-->

        <!--사용자, 검색바 시작-->
        <div class="user">
          <div class="user_name">
            <span class="label_seller">{{this.sellerData.user_division}}</span>
            <p>{{this.sellerData.name}}</p>
          </div>
          <div class="search_bar">
            <input type="text"
              @input="searchInput = $event.target.value"
              :value="searchInput"
              @keyup.enter="search"
              placeholder="상품명을 입력하세요" />
            <button @click="search">
              <img src="@/assets/images/common/search.svg" alt="돋보기" />
            </button>
          </div>
        </div>
        <!--사용자, 검색바 끝-->

        <!--상품 제안 시작-->
        <div class="product">
          <div class="ask">
            <div class="ask_icon">
              <img
                src="@/assets/images/common/icon_mail_w.svg"
                alt="제안하기"
              />
            </div>
            <h1>상품 제안하기</h1>
            <p>
              등록하신 상품 1개를 판매자에게 제안하실 수 있습니다. 공급가가
              비공개인 상품은 제안받는 판매자에게 자동 공개됩니다.
            </p>
          </div>

          <div class="list_wrap">
            <div class="common_table">
              <table>
                <div v-if="this.searchList.length == 0" class="default">제안할 상품 목록이 없습니다.</div>

                <tr v-for="(item, i) in this.searchList"
                  :key="i"
                >
                  <td class="profile">
                    <div v-if="item.original_name">
                      <img :src="item.path + item.save_name" :alt="item.original_name" />
                    </div>
                    <div class="default" v-else>
                      <img src="@/assets/images/common/no_image.svg"/>
                    </div>
                  </td>
                  <td class="product">{{item.name}}</td>
                  <td class="price">{{this.numberFormat(item.supply_price)}}</td>
                  <td class="date">{{this.dateFormat(item.enrollment_date).replace(/-/g, ".")}}</td>
                  <td class="button">
                    <button :disabled="item.request_yn != 'false'" @click="productCheck(item)" class="common_button">선택</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!--상품 제안 끝-->
      </div>
    </div>
    <!--상품 선택 팝업 끝-->

    <!--상품 확인 팝업 시작-->
    <div class="popup popup_s_detail popup_s_detail_02" v-if="this.step==2">
      <button @click="closePopup">
        <img src="@/assets/images/common/icon_close_w.svg" alt="x버튼" />
      </button>
      <div class="inner">
        <!--제목 시작-->
        <div class="product_title">
          <h1>
            <p>상품 제안하기</p>
            <span class="gt">></span>
            <p>상품 확인 (02/03)</p>
          </h1>
        </div>
        <!--제목 끝-->

        <!--사용자, 검색바 시작-->
        <div class="user">
          <div class="user_name">
            <span class="seller">{{this.sellerData.user_division}}</span>
            <p>{{this.sellerData.name}}</p>
          </div>
        </div>
        <!--사용자, 검색바 끝-->

        <!--상품 시작-->
        <div class="product">
          <div class="product_image">
            <img v-if="this.chosenItem.original_name" :src="this.chosenItem.path + this.chosenItem.save_name" :alt="this.chosenItem.original_name" />
            <div class="default" v-else>
              <img src="@/assets/images/common/no_image.svg"/>
            </div>
          </div>
          <div class="text">
            <div class="product_name">
              <h2>상품명</h2>
              <h1>{{this.chosenItem.name}}</h1>
            </div>
            <div class="scale_wrap">
              <div class="scale">
                <p>공급가</p>
                <h1>{{this.numberFormat(this.chosenItem.supply_price)}}</h1>
                <p class="exposure">({{this.chosenItem.supply_price_exposure_yn ? '공개중' : '비공개'}})</p>
              </div>
              <div class="scale">
                <p>소비자가</p>
                <h1>{{this.numberFormat(this.chosenItem.consumer_price)}}</h1>
                <p class="exposure">({{this.chosenItem.consumer_price_exposure_yn ? '공개중' : '비공개'}})</p>
              </div>
              <div class="scale">
                <p>마감일</p>
                <h1>{{this.dateFormat(this.chosenItem.finish_date).replace(/-/g, '.')}}</h1>
              </div>
            </div>
          </div>
        </div>
        <!--상품 끝-->

        <div class="add_token_wrap">
          <div class="num_token">
              <h2>최소 사용 토큰</h2>
              <p><span>{{this.numberFormat(this.chosenItem.min_token)}}</span>개</p>
          </div>
          <div class="num_token">
              <h2>보유 토큰</h2>
              <p><span>{{this.numberFormat(this.user.meta_token)}}</span>개</p>
          </div>
          <div class="add_token">
              <button class="minus" @click="changeMetaToken('-', $event)">
                  -
              </button>
              <div class="fill_number">
                <h2>사용할 토큰</h2>
                <input type="text" :value="meta_token" @input="changeMetaToken('=', $event)"/>
                <span>개</span>
              </div>
              <button class="plus" @click="changeMetaToken('+', $event)">
                  +
              </button>
          </div>
        </div>

        <!--버튼 시작-->
        <div class="last_btn_wrap">
          <h1>선택하신 상품을 제안하시겠습니까?</h1>
          <button @click="apply" id="apply" class="common_button" disabled>
            <p>확인</p>
          </button>
          <button @click="this.step=1; this.chosenItem = {}" class="sub_button">
            <p>이전</p>
          </button>
        </div>
        <!--버튼 끝-->
      </div>
    </div>
    <!--상품 확인 팝업 끝-->

    <!--제안 완료 팝업 시작-->
    <div class="popup popup_s_detail popup_s_detail_03" v-if="this.step==3">
      <button @click="closePopup">
        <img src="@/assets/images/common/icon_close_w.svg" alt="x버튼" />
      </button>
      <div class="inner">
        <!--제목 시작-->
        <div class="product_title">
          <h1>
            <p>상품 제안하기</p>
            <span class="gt">></span>
            <p>상품 확인 (03/03)</p>
          </h1>
        </div>
        <!--제목 끝-->

        <!--사용자 시작-->
        <div class="user">
          <span class="seller">판매자</span>
          <p>판매자닉네임영역</p>
        </div>
        <!--사용자 끝-->

        <!--제안완료 시작-->
        <div class="product">
          <div class="ask">
            <div class="ask_icon">
              <img
                src="@/assets/images/common/icon_mail_send_w.svg"
                alt="제안완료"
              />
            </div>
            <h1>제안완료</h1>
            <p>판매자에게 제안하기를 완료했습니다.</p>
            <p>거래가 성사되지 않으면 토큰은 전액 반환됩니다.</p>
          </div>
        </div>
        <!--제안완료 끝-->

        <!--버튼 시작-->
        <div class="last_btn_wrap">
          <button @click="closePopup" class="common_button">
            <p>닫기</p>
          </button>
        </div>
        <!--버튼 끝-->
      </div>
    </div>
    <!--제안 완료 팝업 끝-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { dateFormat } from "@/utils/dateFormat";
import { numberFormat } from "@/utils/numberFormat";
import { changeMetaToken } from "@/utils/changeMetaToken";

export default {
  props : {
    sellerData : Object,
  },
  mixins : [ fetchData, dateFormat, numberFormat, postData, changeMetaToken ],
  computed : {
    ...mapState('user', ['user']),
    ...mapState('access_token', ['access_token'])
  },
  data(){
    return{
      step: 1,
      providerProduct: [],
      searchInput: '',
      searchList: [],

      chosenItem: {},

      meta_token: 0,
    }
  },
  watch : {
    // 검색 입력값 변경시 동작
    searchInput(search) {
      // searchList 초기화
      if (search == "") {
        this.searchList = this.providerProduct;
      }
    },
    meta_token(val){
      if(val < this.chosenItem.min_token || val == 0){
        document.getElementById('apply').disabled = true;
      } else {
        document.getElementById('apply').disabled = false;
      }
    }
  },
  created(){
    this.fetchProductData();
  },
  methods : {
    // 공급자(로그인 당사자)가 제안할 상품목록을 받아옴
    fetchProductData(){
      this.fetchData(
        '/getSellerDetailProduct',
        (data)=>{
          this.providerProduct = data.list;
          this.searchList=this.providerProduct;
        }
      );
    },
    productCheck(item){
      this.chosenItem = item;
      this.step = 2;
    },
    search(){
      if (this.searchInput == "") {
        return;
      } else {
        this.searchList = this.providerProduct;
        const searchWords = this.searchInput.split(" ");

        searchWords.forEach((word) => {
          this.searchList = this.searchList.filter(
            item => item.name.toLowerCase().includes(word.toLowerCase()) // 상품이름 일치여부
          );
        });
      }
    },
    tokenInput(event){
      const value = event.target.value.replace(/[^0-9]/g, "");
      if(value == '' || value < 0){
        event.target.value = 0;
        this.meta_token = 0;
      } else if(value > this.user.meta_token){
        event.target.value = this.user.meta_token;
        this.meta_token = this.user.meta_token
      } else {
        this.meta_token = value;
      }
    },
    apply(){
      if(this.meta_token < this.chosenItem.min_token){
        this.$store.commit('alert', '최소 사용토큰을 확인해주세요')
      } else {
        this.postData('/requestProviderSeller',
          {
            product_pk: this.chosenItem.product_pk,
            seller_id: this.sellerData.id,
            meta_token: this.meta_token,
          }, ()=>{
            this.$store.commit("user/metaTokenUse", this.meta_token);
            this.step=3;
          }
        );
      }
    },
    closePopup(){
      this.$emit('closePopup');
    }
  }
}
</script>

<style scoped src="@/assets/css/components/commonPopup.css">
</style>