<template>
    <div class="content_container partner_detail seller_detail">
        <div class="inner">
            <div class="left_con">
                <!--left 판매자 소개 시작-->
                <div class="partner_wrap">
                    <div class="profile_wrap">
                        <div class="user">
                            <div class="user_image" v-if="this.sellerData.save_name">
                                <img
                                    :src="this.sellerData.path + this.sellerData.save_name"
                                    :alt="this.sellerData.original_name"
                                />
                            </div>
                            <div class="user_image" v-else>
                                <img src="@/assets/images/common/charactor_01.png" alt="프로필사진" />
                            </div>
                        </div>
                        <div class="text">
                            <div class="user_type">
                                <span class="label_seller">{{ this.sellerData.user_division }}</span>
                                <p>{{ this.sellerData.businessman_division }}</p>
                            </div>
                            <div class="user_class" :class="this.sellerData.level_icon">
                                <p>{{ this.sellerData.level }}</p>
                            </div>
                            <div class="user_name_wrap">
                                <h1 class="user_name">{{ this.sellerData.name }}</h1>
                                <div class="active_wrap" :class="this.sellerData.state == '정상' ? '' : 'unactive'">
                                    <p>
                                        {{ this.sellerData.state != "정상" ? "비활동" : "활동 중" }}
                                    </p>
                                </div>
                            </div>
                            <div class="user_check_wrap">
                                <div class="user_check user_check_provider">
                                    <p>본인인증</p>
                                    <div
                                        class="btn_check"
                                        :class="this.sellerData.user_certification_yn == 'false' ? 'disabled' : ''"
                                    >
                                        <img v-if="this.sellerData.user_certification_yn == 'false'" src="@/assets/images/common/icon_close_w.svg" />
                                        <img v-else src="@/assets/images/common/icon_check_w.svg"/>
                                    </div>
                                </div>

                                <div class="user_check user_check_provider">
                                    <p>사업자인증</p>
                                    <div
                                        class="btn_check"
                                        :class="
                                            this.sellerData.businessman_certification_yn == 'false' ? 'disabled' : ''
                                        "
                                    >
                                        <img v-if="this.sellerData.businessman_certification_yn == 'false'" src="@/assets/images/common/icon_close_w.svg" />
                                        <img v-else src="@/assets/images/common/icon_check_w.svg"/>
                                    </div>
                                </div>

                                <div class="user_check user_check_provider">
                                    <p>채널인증</p>
                                    <div
                                        class="btn_check"
                                        :class="this.sellerData.channel_certification_yn == 'false' ? 'disabled' : ''"
                                    >
                                        <img v-if="this.sellerData.channel_certification_yn == 'false'" src="@/assets/images/common/icon_close_w.svg" />
                                        <img v-else src="@/assets/images/common/icon_check_w.svg"/>
                                    </div>
                                </div>

                                <div class="user_check user_check_provider">
                                    <p>거래역량인증</p>
                                    <div
                                        class="btn_check"
                                        :class="this.sellerData.sales_certification_yn == 'false' ? 'disabled' : ''"
                                    >
                                        <img v-if="this.sellerData.sales_certification_yn == 'false'" src="@/assets/images/common/icon_close_w.svg" />
                                        <img v-else src="@/assets/images/common/icon_check_w.svg"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h1 class="subtitle">평가</h1>
                    <div class="estimate">
                        <div class="like_wrap">
                            <p>호감도</p>
                            <div class="star_wrap star_01">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_02">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_03">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_04">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_05">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="like_wrap">
                            <p>리뷰</p>
                            <div class="star_wrap star_01">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_02">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_03">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_04">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_05">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="like_wrap">
                            <p>활동</p>
                            <div class="star_wrap star_01">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_02">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_03">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_04">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_05">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                        </div>
                    </div>

                    <h1 class="subtitle">소개</h1>
                    <div class="explain" id="explain">
                        <p>{{ this.sellerData.introduce }}</p>
                        <!-- <span class="gt" @click="expandExplain">▼</span> -->
                    </div>

                    <h1 class="subtitle">해시태그</h1>
                    <div class="hashtag">
                        <div class="hashtag_word" v-for="(item, i) in this.sellerData.hashtag" :key="i">
                            {{ item.hashtag }}
                        </div>
                        <p v-if="!this.sellerData.hashtag || this.sellerData.hashtag.length == 0">
                            해시태그가 없습니다
                        </p>
                    </div>

                    <div class="info_wrap">
                        <div class="info">
                            <h1>판매 카테고리</h1>
                            <span v-for="(item, i) in this.sellerData.category" :key="i">{{ item.category }}</span>
                        </div>

                        <div class="info">
                            <h1>판매 채널</h1>
                            <span v-for="(item, i) in this.sellerData.channel" :key="i">{{ item.channel }}</span>
                        </div>

                        <div class="info info_place">
                            <h1>활동지역</h1>
                            <span v-for="(item, i) in this.sellerData.area" :key="i">{{ item.area }}</span>
                        </div>

                        <div class="info">
                            <h1>예산 정보</h1>
                            <p>{{ this.sellerData.budget }}</p>
                        </div>

                        <div class="info">
                            <h1>판매 경력</h1>
                            <p>{{ this.sellerData.career }}</p>
                        </div>

                        <div class="info">
                            <h1>등록일</h1>
                            <p>{{ this.dateFormat(this.sellerData.enrollment_date) }}</p>
                        </div>
                    </div>

                    <div class="btn_box_wrap">
                        <div class="btn_box">
                            <div class="icon_wrap">
                                <img src="@/assets/images/common/icon_store_g.svg" alt="store" />
                                <img src="@/assets/images/common/icon_store_b.svg" alt="store_hover" />
                            </div>
                            <p>스토어</p>
                        </div>
                        <div class="btn_box">
                            <div class="icon_wrap">
                                <img src="@/assets/images/common/icon_youtube_g.svg" alt="youtube" />
                                <img src="@/assets/images/common/icon_youtube_b.svg" alt="youtube_hover" />
                            </div>
                            <p>유튜브</p>
                        </div>
                        <div class="btn_box">
                            <div class="icon_wrap">
                                <img src="@/assets/images/common/icon_link_share_g.svg" alt="link_share" />
                                <img src="@/assets/images/common/icon_link_share_b.svg" alt="link_share_hover" />
                            </div>
                            <p>공유</p>
                        </div>
                    </div>
                </div>
                <!--left 판매자 끝 시작-->

                <!--left 판매자 등록매칭 시작-->
                <div class="matching_wrap">
                    <h1>
                        <span>"{{ this.sellerData.name }}"</span>님께서 등록한 매칭
                    </h1>
                    <div class="matching_list_wrap">
                        <div
                            class="matching_list"
                            v-for="(item, i) in this.sellerData.matching"
                            :key="i"
                            @click="this.$router.push(`/matching/detail/${item.matching_pk}`)"
                        >
                            <div class="product_image">
                                <img
                                    v-if="item.path && item.save_name"
                                    :src="item.path + item.save_name"
                                    :alt="item.original_name"
                                />
                                <div v-if="!item.path && !item.save_name" class="no_image">
                                    <img src="@/assets/images/common/no_image.svg" />
                                </div>
                                <span class="state" :class="item.state == '매칭중' ? '' : 'unactive'">{{
                                    item.state
                                }}</span>
                            </div>
                            <h2>
                                {{ item.name }}
                            </h2>
                        </div>
                        <div class="default" v-if="this.sellerData.matching.length == 0">등록한 매칭이 없습니다.</div>
                    </div>
                </div>
                <!--left 판매자 등록매칭 끝-->
            </div>

            <!--right_fixed 시작-->
            <div class="right_fixed">
                <div class="text">
                    <h1>활동내역</h1>
                    <div class="line"></div>
                    <div class="scale_wrap">
                        <div class="scale">
                            <h2>거래 완료</h2>
                            <p>
                                <span>{{ this.numberFormat(this.sellerData.deal_count) }}</span
                                >건
                            </p>
                        </div>
                        <div class="scale">
                            <h2>매칭 등록</h2>
                            <p>
                                <span>{{ this.numberFormat(this.sellerData.matching_count) }}</span
                                >건
                            </p>
                        </div>
                        <div class="scale">
                            <h2>보낸 제안</h2>
                            <p>
                                <span>{{ this.numberFormat(this.sellerData.request_count) }}</span
                                >건
                            </p>
                        </div>
                        <div class="scale">
                            <h2>받은 제안</h2>
                            <p>
                                <span>{{ this.numberFormat(this.sellerData.response_count) }}</span
                                >건
                            </p>
                        </div>
                        <div class="scale">
                            <h2>보유 토큰</h2>
                            <p>
                                <span>{{ this.numberFormat(this.sellerData.token_count) }}</span
                                >개
                            </p>
                        </div>
                        <h6>(1토큰 당 10,000원)</h6>
                    </div>
                </div>
                <div class="btn_wrap">
                    <button
                        v-if="this.user.user_division == '공급자'"
                        type="button"
                        class="common_button"
                        @click="openPopup"
                    >
                        제안하기
                    </button>
                    <button type="button" class="sub_button" @click="this.$store.commit('alert', '준비중입니다')">
                        추천하기
                    </button>
                </div>

                <!--btn_left 누르면 옆에서 팝업 나타나기-->
                <div class="btn_left" @click="openRightFixed">
                    <span class="gt">&lt;</span>
                </div>
            </div>
            <!--right_fixed 끝-->
        </div>

        <ProviderToSeller v-if="showPopup" :sellerData="sellerData" @closePopup="closePopup" />
    </div>
</template>

<script>
import ProviderToSeller from "@/components/Proposal/ProviderToSeller";

import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";
import { sortDown } from "@/utils/sortItems";
import { mapState } from "vuex";

export default {
    props: {
        id: String,
    },
    components: {
        ProviderToSeller,
    },
    mixins: [fetchData, numberFormat, dateFormat, sortDown],
    computed: {
        ...mapState("user", ["user"]),
    },
    mounted() {
        this.fetchSellerData();
    },
    data() {
        return {
            showPopup: false,

            sellerData: {
                matching: [],
                channel: [],
                category: [],
                area: [],
                enrollment_date: [],
                // 등등
            },
        };
    },
    methods: {
        // 판매자 데이터 불러오기
        fetchSellerData() {
            this.fetchData(`/getSellerDetail/${this.id}`, (data) => {
                this.sellerData = data.data;

                const finished = this.sortByDown(
                    data.data.matching.filter((item) => item.state === "마감"),
                    "enrollment_date",
                );
                const matching = this.sortByDown(
                    data.data.matching.filter((item) => item.state !== "마감"),
                    "enrollment_date",
                );
                this.sellerData.matching = matching.concat(finished);
            });
        },
        // 소개 펼치기
        expandExplain() {
            document.getElementById("explain").classList.toggle("expand");
        },
        openRightFixed(event){
            event.currentTarget.classList.toggle('open');
            const right_fixed = document.querySelector('.right_fixed');
            right_fixed.classList.toggle('open');
        },
        // 팝업 펼치기
        openPopup() {
            this.showPopup = true;
        },
        closePopup() {
            this.showPopup = false;
        },
    },
};
</script>

<style scoped src="@/assets/css/layout_provider.css"></style>
